import React from "react";

function Spades(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
            <path
                fill="#3C3A41"
                d="M478.56 378.37c-34.932 57.046-83.622 69.333-118.31 69.593-18.031.14-36.083-3.012-53.263-9.196 3.772 15.59 8.615 24.075 12.688 26.937l6.374 4.483v41.817h-140.09v-41.816l6.374-4.483c4.073-2.862 8.916-11.347 12.688-26.937-17.181 6.184-35.222 9.336-53.263 9.196-34.692-.26-83.382-12.548-118.31-69.593-28.158-45.989-28.898-100.53-2.041-149.63 20.723-37.904 52.833-68.113 83.882-97.331 5.463-5.133 10.617-9.986 15.75-14.909 19.242-18.482 38.724-36.293 57.556-53.513 18.702-17.101 38.044-34.782 57.016-53.003L256.007.008l10.397 9.976c18.972 18.221 38.314 35.902 57.016 53.003 18.832 17.221 38.314 35.032 57.556 53.513 5.133 4.923 10.286 9.776 15.75 14.909 31.049 29.218 63.159 59.427 83.882 97.331 26.856 49.102 26.116 103.65-2.042 149.63z"
            ></path>
            <path
                fill="#17171A"
                d="M478.56 378.37c-34.932 57.046-83.622 69.333-118.31 69.593-18.031.14-36.083-3.012-53.263-9.196 3.772 15.59 8.615 24.075 12.688 26.937l6.374 4.483v41.817h-70.044v-512l10.397 9.976c18.972 18.221 38.314 35.902 57.016 53.003 18.832 17.221 38.314 35.032 57.556 53.513 5.133 4.923 10.286 9.776 15.75 14.909 31.049 29.218 63.159 59.427 83.882 97.331 26.856 49.102 26.116 103.65-2.042 149.63z"
            ></path>
        </svg>
    );
}

export default Spades;
