import React from "react";
import Card from "./Card";
import './Hands.css';

export default class Hands extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCards: [],
        };
    }

    handleCard(card, event) {
        if (this.props.disabled) {
            return;
        }
        let selectedCards = this.state.selectedCards.filter(c => c.value !== card.value || c !== card);
        if (!this.isSelected(card) || !(event.shiftKey || this.props.multiple)) {
            selectedCards.push(card);
        }
        this.setState({
            selectedCards
        });
        if (!(event.shiftKey || this.props.multiple)) {
            this.sendCards(selectedCards);
        }
    }

    sendCards(selectedCards) {
        if (this.props.onSelect && selectedCards.length > 0) {
            this.props.onSelect(selectedCards);
            this.setState({
                selectedCards: []
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.multiple === true && this.props.multiple === false) {
            this.sendCards(this.state.selectedCards);
        }
    }

    isSelected(card) {
        let found = this.state.selectedCards.find(c => c === card || c.value === card.value);
        return !!found;
    }

    render() {
        let hands = this.props.hands;
        // border: 2px solid green;
        let myStyle = {position: 'relative', ...this.props.style};
        if (!this.props.disabled) {
            myStyle.border = '1px solid #00AA00';
            myStyle.height = 152;
        }
        if (this.props.out) {
            hands = [];
            for (let i = 0; i < this.props.hands.length; i++) {
                hands.push({}); // empty card.
            }
        }
        return (
            <>
                <div className={"hand-" + this.props.playerCount} style={myStyle}>
                    {
                        hands.map((card, index) => {
                            let style = {zIndex: index, top: 0, left: 30 * index, cursor: 'pointer'};
                            if (this.isSelected(card)) {
                                style.border = '2px solid green';
                            }
                            return <Card key={index}
                                         disabled={this.props.disabled}
                                         card={card}
                                         onClick={(e) => this.handleCard(card, e)}
                                         style={style}/>
                        })
                    }
                </div>
            </>
        );
    }
}