import React from "react";

function Heart(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
            <path
                fill="#E70020"
                d="M491.47 250.78c-21.734 39.74-55.445 71.461-88.047 102.13-5.748 5.409-11.167 10.507-16.566 15.686-20.225 19.425-40.699 38.14-60.504 56.255-19.675 17.975-40.009 36.57-59.964 55.735l-10.387 9.978-10.387-9.977c-19.955-19.165-40.289-37.76-59.964-55.735-19.805-18.115-40.279-36.83-60.504-56.255-5.399-5.179-10.817-10.277-16.566-15.686-32.601-30.672-66.313-62.394-88.047-102.13-28.102-51.388-27.323-108.44 2.13-156.56 36.54-59.664 87.457-72.511 123.74-72.791 30.972-.24 61.604 8.658 88.617 25.693l20.984 13.147 21.024-13.167c26.973-17.016 57.635-25.873 88.577-25.673 36.28.28 87.197 13.127 123.74 72.791 29.453 48.117 30.233 105.17 2.13 156.56z"
            ></path>
            <path
                fill="#990013"
                d="M491.47 250.78c-21.734 39.74-55.445 71.461-88.047 102.13-5.748 5.409-11.167 10.507-16.566 15.686-20.225 19.425-40.699 38.14-60.504 56.255-19.675 17.975-40.009 36.57-59.964 55.735l-10.387 9.978v-430.3l21.024-13.167c26.973-17.016 57.635-25.873 88.577-25.673 36.28.28 87.197 13.127 123.74 72.791 29.453 48.117 30.233 105.17 2.13 156.56z"
            ></path>
        </svg>
    );
}

export default Heart;
