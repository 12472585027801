import React from "react";

import BackRed from "./img/BackRed";

const MAPPING = {
    '1': 'A',
    'D': 'Q',
    'R': 'K',
    'V': 'J',
    'PIQUE': 'S',
    'COEUR': 'H',
    'TREFLE': 'C',
    'CARREAU': 'D',
};

const COLORS = {
    'S': 'PIQUE',
    'H': 'COEUR',
    'C': 'TREFLE',
    'D': 'CARREAU',
}

const NUMBER_INDEXES = {
    "10": 0,
    "9": 1,
    "7": 2,
    "R": 3,
    "V": 4,
    "D": 5,
    "1": 6,
    "8": 7,
}

const COLOR_INDEXES = {
    'PIQUE': 0,
    'COEUR': 1,
    'TREFLE': 2,
    'CARREAU': 3,
}

export const parseNumber = (card) => {
    return card.substring(0, card.length-1);
}

export const parseColor = (card) => {
    return card.substring(card.length-1);
}

export const getCard = (card) => {
    if (typeof card === 'object' || typeof card === 'undefined') {
        return card;
    }
    let number = parseNumber(card);
    let color = parseColor(card);
    color = COLORS[color] || color;
    let value = card;
    let index = NUMBER_INDEXES[number] * 4 + COLOR_INDEXES[color];
    return {number, color, value, index};
}

export const CARD_DIMENSION = {
    with: 100, height: 150
};

export default class Card extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    getCard() {
        let card = this.props.card;
        return getCard(card);
    }


    getLink(props) {
        let card = this.getCard();
        return card.number && card.color ?
            <img alt={card.number + '-' + card.color}
                 onClick={this.handleClick}
                 style={{position: "absolute", ...CARD_DIMENSION, ...this.props.style}}
                 src={`./cards/${this.getName()}.svg`}
            /> : <BackRed {...props} style={{position: "absolute", ...CARD_DIMENSION, ...this.props.style}}/>;
    }

    getName() {
        let card = this.getCard();
        let number = MAPPING[card.number] || card.number;
        let color = MAPPING[card.color] || card.color;
        return `${number}${color}`
    }

    handleClick(event) {
        event.persist();
        console.log('event', event);
        if(this.props.onClick) {
            this.props.onClick(event);
        }
    }

    render() {
        return (this.getLink({...CARD_DIMENSION, ...this.props.style}));
    }
}