import React from "react";

import Deck from './Deck';

export default class HideHands extends React.Component {

    render() {
        let myStyle = {textAlign: 'center', ...this.props.style};
        if(this.props.currentPlayer && this.props.currentPlayer.name === this.props.player.name) {
            myStyle.border = '1px solid #00AA00';
        }
        let point = this.props.player.point;
        return (
            <div className={this.props.className} style={myStyle}>
                <h3 style={{zIndex: 10, color: point < 50 ? 'green' : (point < 100 ? 'orange' : 'red')}}>{this.props.player.name} : {this.props.player.point}</h3>
                <Deck left={10} count={this.props.player.handSize} color={this.props.color}/>
            </div>
        );
    }
}