import React from "react";
import BackBlue from './img/BackBlue';
import BackRed from './img/BackRed';
import {CARD_DIMENSION} from "./Card";
import './Deck.css';

export const COLORS = {
    'blue': <BackBlue/>,
    'red': <BackRed/>
};

export default class Deck extends React.Component {

    getLink(props = {}) {
        return this.props.color === 'red' ? <BackRed {...props}/> : <BackBlue {...props}/>
    }

    render() {
        let decks = [];
        for (let index = 0; index < this.props.count; index++) {
            decks.push(<span key={index}
                             onClick={this.props.onClick}
                             style={{
                                 position: "absolute",
                                 with: 100,
                                 height: 150,
                                 zIndex: index,
                                 top: 0,
                                 left: (this.props.left || 3) * index, ...this.props.style
                             }}
            >{this.getLink({
                with: 100,
                height: 150
            })}</span>)
        }
        if (decks.length === 0) {
            decks.push(<span key={0}
                             onClick={this.props.onClick}
                             style={{
                                 position: "absolute",
                                 zIndex: 0,
                                 top: 0,
                                 left: 0, ...CARD_DIMENSION, ...this.props.style
                             }}
            >{this.getLink(CARD_DIMENSION)}</span>);
        }
        let point = this.props.point;
        return (
            <div>
                <h2>{this.props.title}</h2>
                <div className="Deck">
                    {decks}
                </div>
                {this.props.showPoints && <span style={{color: point < 50 ? 'green' : (point < 100 ? 'orange' : 'red')}} className="point">Mes Points : {this.props.point}</span>}
            </div>
        );
    }
}