import React from "react";
import Spades from "./img/Spades";
import Heart from "./img/Heart";
import Clubs from "./img/Clubs";
import Diamonds from "./img/Diamonds";

const MAPPING = {
    'PIQUE': (props) => <Spades {...props}/>,
    'COEUR': (props) => <Heart {...props}/>,
    'TREFLE': (props) => <Clubs {...props}/>,
    'CARREAU': (props) => <Diamonds {...props}/>,
};

export default class Color extends React.Component {

    getColor(props = {}) {
        return MAPPING[this.props.color](props) || this.props.color
    }

    render() {
        if (!this.props.color) {
            return null;
        }
        return (
            <span {...this.props}>
                {this.getColor(this.props)}
            </span>
        );
    }
}