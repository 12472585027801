import React from 'react';
import './App.css';
import Alert, {sendErrorAlert, sendSuccessAlert} from './Alert'
import Hands from "./Hands";
import Table from "./Table";
import AskColor from "./AskColor";
import Color from "./Color";
import Deck from "./Deck";
import Players from "./Players";
import Messages from "./Messages";
import Sounder from "./Sounder";
import Header from "./Header";
import {getCard} from "./Card";
import {adjectives, animals, colors, names, uniqueNamesGenerator} from "unique-names-generator";

const randomGroupNameConfig = {
    dictionaries: [adjectives, colors, animals],
    style: 'capital',
    separator: ' '
};

const randomPlayerNameConfig = {
    dictionaries: [names]
}


function parseQueryString() {
    var queryString = window.location.search;
    queryString = decodeURI(queryString.substring(1));

    var params = {}, queries, temp, i, l;

    // Split into key/value pairs
    queries = queryString.split("&");

    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }

    return params;
}

const {WebSocket, location} = window;

const ASK = 'ASK';
const ASKED = 'ASKED';
const DRAWN = 'DRAWN';
const PLAY = 'PLAY';
const PLAYED = 'PLAYED';
const GROUP_NEW = 'GROUP_NEW';
const GROUP_NEW_PLAY = 'GROUP_NEW_PLAY';
const JOIN = 'JOIN';
const REMOVE = 'REMOVE';
const ENDED = 'ENDED';
const UPDATE = 'UPDATE';
const PENDING = 'PENDING';
const TERMINATE = 'TERMINATE';
const LEAVE = 'LEAVE';
const WAITING = 'WAITING';

const RETRY_TIMEOUT = 5000;
const MESSAGE_TIMEOUT = 5000;
const ERROR_TIMEOUT = 5000;


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groupName: '',
            playerName: '',
            capacity: 2,
            owner: '',
            groups: [],
            players: [],
            iaPlayers: [],
            tables: [],
            hands: [],
            cmd: '',
            color: '',
            selectedCards: [],
            topCard: {},
            myGroup: {},
            messages: [],
            multiple: false,
            secret: '',
            enableAudio: true,
            aiPlayer: '',
            asc: true
        };
        this.onOpen = this.onOpen.bind(this);
        this.onMessage = this.onMessage.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createGroup = this.createGroup.bind(this);
        this.removeGroup = this.removeGroup.bind(this);
        this.joinGroup = this.joinGroup.bind(this);
        this.handleCard = this.handleCard.bind(this);
        this.handleDrawn = this.handleDrawn.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleAsk = this.handleAsk.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.leaveGroup = this.leaveGroup.bind(this);
        this.handleHeader = this.handleHeader.bind(this);
        this.waitForConnection = this.waitForConnection.bind(this);
        this.send = this.send.bind(this);
        this.retry = 0;
        this.timer = 0;
        this.opened = false;
    }

    handleChange(event) {
        let type = event.target.type;
        let name = event.target.name;
        let value = event.target.value;
        if (type === 'checkbox') {
            value = event.target.checked;
        }
        this.setState({
            [name]: value
        });
    }

    handleAsk(color) {
        if (this.state.cmd !== ASK) {
            return;
        }
        this.setState({
            cmd: ASKED
        }, () => {
            this.send(`${this.state.playerName}|${ASKED}|${this.state.groupName}|${color}`);
        });
    }

    handleDrawn(event) {
        if (this.state.cmd !== PLAY) {
            return;
        }
        this.setState({
            cmd: DRAWN
        }, () => {
            this.send(`${this.state.playerName}|${DRAWN}|${this.state.groupName}|${this.state.groupName}`);
        });
    }

    handleCard(card, event) {
        let selectedCards = this.state.selectedCards;
        if (event.target.checked) {
            selectedCards.push(card);
        } else {
            selectedCards = selectedCards.filter(c => {
                return card.number !== c.number && card.color !== c.color
            });
        }
        this.setState({
            selectedCards
        });
    }

    handleSelect(selectedCards) {
        // let cards = JSON.stringify(selectedCards);
        let cards = selectedCards.map(c => c.value || c).join(";");
        if (selectedCards.length === 0) {
            return;
        }
        this.setState({
            selectedCards: [],
            cmd: PLAYED
        }, () => {
            this.send(`${this.state.playerName}|${PLAYED}|${this.state.groupName}|${cards}`);
        });
    }

    handleUpdate(event) {
        event.preventDefault();
        let data = `${this.state.groupName}|${UPDATE}|${this.state.playerName}|${this.state.secret}`;
        // if (this.state.aiPlayer) {
        //     data = `${data}|${this.state.aiPlayer}`;
        // }
        this.send(data);
    }

    onClose(event) {
        if (this.retry === 0) {
            this.retry++;
            this.timer = window.setInterval(() => {
                if (this.retry < 10) {
                    this.initConnexion();
                    this.retry++;
                    console.log('connexion lost, retry to  connect ' + this.retry);
                } else {
                    console.log('retry max', this.retry);
                    window.clearInterval(this.timer);
                }
            }, RETRY_TIMEOUT);
        }
    }

    onOpen(event) {
        if (this.opened) {
            return;
        }
        let params = parseQueryString();
        let groupName = uniqueNamesGenerator(randomGroupNameConfig);
        let owner = uniqueNamesGenerator(randomPlayerNameConfig);
        let state = localStorage.getItem('state') || '{}';
        console.warn('state', state);
        let stateObj = JSON.parse(state);
        let toDelete = [];
        for (let key in stateObj) {
            if (!stateObj[key]) {
                toDelete.push(key);
            }
        }
        for (let key of toDelete) {
            console.log('key', key);
            delete stateObj[key];
        }
        if (stateObj.myGroup?.state === ENDED || stateObj.myGroup?.state === TERMINATE) {
            stateObj = {};
        }
        let defaultValue = {
            capacity: 2,
            groupName: groupName,
            owner: owner,
            playerName: owner
        };
        if (!(params.cmd === GROUP_NEW_PLAY || params.cmd === GROUP_NEW)) {
            defaultValue = {}
        }
        let jState = Object.assign(defaultValue, stateObj, params);
        if (!jState.secret && (jState.cmd === GROUP_NEW_PLAY || jState.cmd === GROUP_NEW)) {
            jState.secret = '' + Math.floor(Math.random() * 1000000);
        }
        this.setState(jState, () => {
            if (jState.cmd === GROUP_NEW_PLAY || jState.cmd === GROUP_NEW) {
                event.cmd = jState.cmd;
                this.createGroup(event);
            } else {
                this.joinGroup(event);
            }
        });
        if (this.timer) {
            window.clearInterval(this.timer);
        }
        this.opened = true;
    }

    setState(state, callback) {
        super.setState(state, () => {
            localStorage.setItem('state', JSON.stringify(this.state));
            if (callback) {
                callback();
            }
        });
    }

    onMessage(event) {
        let eventData = event.data;
        let data = JSON.parse(eventData) || {};
        console.log('data', data);
        if (data.message) {
            let message = data.message;
            if (data.sender) {
                message = `${data.sender} : ${message}`;
            }
            let messages = this.state.messages || [];
            messages = [message, ...messages];
            this.setState({
                messages
            });
        }
        if (data.error) {
            sendErrorAlert({
                title: 'Message',
                message: `${data.error}`,
                timeout: ERROR_TIMEOUT
            });
        } else {
            if (data.type === 'message') {
                sendSuccessAlert({
                    title: 'Message',
                    message: `${data.message}`,
                    timeout: MESSAGE_TIMEOUT
                });
                if (data.cmd) {
                    this.setState({
                        cmd: data.cmd
                    });
                }
            } else if (data.type === 'data') {
                this.setState(data);
            } else if (data.type === 'hands') {
                this.setState({
                    hands: data.hands.map(c => getCard(c))
                })
            } else if (data.type === 'topCard') {
                this.setState({
                    topCard: getCard(data.topCard)
                })
            } else if (data.type === 'myGroup') {
                this.setState(data);
            } else if (data.type === 'game') {
                if (data.currentPlayer && data.currentPlayer.name !== this.state.playerName) {
                    data.cmd = WAITING;
                }
                this.setState(data);
            }
        }
    }

    componentDidMount() {
        this.initConnexion();
    }

    initConnexion() {
        let host = location.host;
        if (host.indexOf('localhost') < 0) {
            this.socket = new WebSocket('wss://' + host + '/ws');
        } else {
            this.socket = new WebSocket('ws://localhost:8151/ws');
        }
        this.socket.onopen = this.onOpen;
        this.socket.onmessage = this.onMessage;
        this.socket.onclose = this.onClose;
        return this.socket;
    }

    createGroup(event) {
        event.preventDefault();
        let cmd = GROUP_NEW;
        if (event.target.name === 'play' || event.cmd === GROUP_NEW_PLAY) {
            cmd = GROUP_NEW_PLAY
        }
        let data = `${this.state.groupName}|${cmd}|${this.state.capacity}|${this.state.owner}|${this.state.secret}`;
        if (this.state.aiPlayer) {
            data = `${data}|${this.state.aiPlayer}`;
        } else if (cmd === GROUP_NEW_PLAY) {
            data = `${data}|BASIC`;
        }
        this.setState({
            playerName: this.state.owner
        });
        this.send(data);
        if (cmd !== GROUP_NEW_PLAY) {
            let message = `Invitation: https://i151.djammadev.com?groupName=${this.state.groupName}&capacity=${this.state.capacity}&secret=${this.state.secret}&cmd=JOIN&playerName=Player_${Math.floor(Math.random() * 1000)}`;
            let messages = this.state.messages || [];
            messages = [message, ...messages];
            this.setState({
                messages
            });
        }
    }

    send(data, callback) {
        console.log('send(data)', data);
        if (this.socket.readyState === 3) {
            this.socket.onopen = null;
            this.socket.onmessage = null;
            this.socket.onclose = null;
            this.socket = this.initConnexion();
        }
        this.waitForConnection(() => {
            this.socket.send(data);
            if (typeof callback !== 'undefined') {
                callback();
            }
        }, 1000);
    }

    waitForConnection(callback, interval) {
        if (this.isReady()) {
            callback();
            this.reconnectTimer = 0;
        } else {
            let that = this;
            // optional: implement backoff for interval here
            if (that.reconnectTimer) {
                clearInterval(that.reconnectTimer);
            }
            that.reconnectTimer = setTimeout(function () {
                that.waitForConnection(callback, interval);
            }, interval);
        }
    };

    joinGroup(event) {
        if ((this.state.playerName === '' && this.state.owner === '') || this.state.groupName === '' || this.state.secret === '') {
            return;
        }
        let source = event.target.name;
        console.log('source', source);
        event.preventDefault();
        let playerName = this.state.playerName || this.state.owner;
        if (this.state.aiPlayer && Boolean(source)) {
            playerName = `${this.state.aiPlayer}`
            let existing = this.state.players.find(p => p.name === playerName);
            let index = 0;
            while (existing) {
                index += 1;
                // eslint-disable-next-line
                existing = this.state.players.find(p => p.name === `${playerName} ${index}`);
            }
            if (index > 0) {
                playerName = `${playerName} ${index}`;
            }
        }
        let data = `${playerName}|${JOIN}|${this.state.groupName}|${this.state.secret}`;
        if (this.state.aiPlayer) {
            data = `${data}|${this.state.aiPlayer}`;
        }
        console.log('joinGroup::data', data)
        this.send(data);
    }

    removeGroup(event) {
        event.preventDefault();
        this.setState({
            myGroup: {}
        }, () => {
            let data = `${this.state.groupName}|${REMOVE}|${this.state.playerName}|${this.state.secret}`;
            this.send(data);
        });
    }

    leaveGroup(event) {
        event.preventDefault();
        let playerName = this.state.playerName;
        let groupName = this.state.groupName;
        let secret = this.state.secret;
        this.setState({
            groupName: '',
            playerName: '',
            capacity: '',
            owner: '',
            tables: [],
            hands: [],
            cmd: '',
            color: '',
            selectedCards: [],
            topCard: {},
            myGroup: {},
            messages: [],
            multiple: false,
            secret: ''
        }, () => {
            let data = `${playerName}|${LEAVE}|${groupName}|${secret}`;
            this.send(data);
        });
    }

    handleHeader(event) {
        event.preventDefault();
    }

    isReady() {
        return this.socket.readyState === 1;
    }

    render() {
        let myGroup = this.state.myGroup;
        // (this.state.groups || []).find(g => g.name === this.state.groupName);
        let players = [];
        if (myGroup) {
            players = myGroup.players || [];
        }
        let watchers = myGroup.watchers || [];
        let myIndex = -1;
        let me = {};
        players.map((p, i) => {
            if (p.name === this.state.playerName) {
                if (!p.watcher)
                    myIndex = i;
                me = p;
            }
            return p;
        });
        /*if (!me.name) {
            me = {watcher: true, name: this.state.playerName}
            players.push(me);
            myIndex = players.length - 1;
        }*/
        return (
            <>
                <Alert/>
                <Header onClick={this.handleHeader}/>
                <div style={{marginTop: 60}} className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <ul className="nav nav-tabs nav-justified">
                                {/*<li className="nav-item">*/}
                                {/*    <a data-toggle="tab" href="#player" className="nav-link">Rejoindre un*/}
                                {/*        groupe</a></li>*/}
                                <li className="nav-item">
                                    <a data-toggle="tab" href="#group" className="nav-link active">Créer ou rejoindre un
                                        groupe</a>
                                </li>
                                <li className="nav-item">
                                    <a data-toggle="tab" href="#messages" className="nav-link">Messages</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div id="group" className="tab-pane container fade in active show">
                                    <form id={"form-group"} onSubmit={this.createGroup}>
                                        <div className="form-group">
                                            <label htmlFor="groupName">Nom du groupe</label>
                                            <input type="text" className="form-control" id="groupName"
                                                   placeholder="Nom du groupe"
                                                   onChange={this.handleChange}
                                                   name="groupName"
                                                   value={this.state.groupName}
                                                   autoComplete="new-password"
                                                   list="list-group"
                                                   required/>
                                            <datalist id="list-group">
                                                {
                                                    this.state.groups.map(group => {
                                                        return <option key={group.name}
                                                                       style={{color: group.capacity >= (group.players || []).length ? 'green' : ''}}
                                                                       value={group.name}>{group.name + ' (' + group.capacity + ')'}</option>
                                                    })
                                                }
                                            </datalist>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="capacity">Nombre de joueur</label>
                                            <input type="number" className="form-control" id="capacity"
                                                   placeholder="Nombre de joueur"
                                                   onChange={this.handleChange}
                                                   min={2}
                                                   max={6}
                                                   name="capacity"
                                                   value={this.state.capacity}
                                                   autoComplete="new-password"
                                                   required/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="owner">Votre nom</label>
                                            <input type="text" className="form-control" id="owner"
                                                   placeholder="Votre nom"
                                                   onChange={this.handleChange}
                                                   name="owner"
                                                   value={this.state.owner}
                                                   autoComplete="new-password"
                                                   required/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="aiPlayer-select">Un joueur IA</label>
                                            <select className="form-control" id="aiPlayer-select"
                                                    onChange={this.handleChange}
                                                    name="aiPlayer"
                                                    value={this.state.aiPlayer}>
                                                {/*<option value="">Type de Joueur</option>*/}
                                                <option value="">Humain</option>
                                                {
                                                    this.state.iaPlayers.map((ia, index) => {
                                                        return <option key={index}
                                                                       value={ia}>{(ia !== 'Observateur' ? 'Robot : ' : '') + ia}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="join-secret">Code secret</label>
                                            <input type="password"
                                                   className="form-control"
                                                   id="join-secret"
                                                   placeholder="Code secret"
                                                   onChange={this.handleChange}
                                                   name="secret"
                                                   value={this.state.secret}
                                                   autoComplete="new-password"
                                                   required/>
                                        </div>
                                        <div style={{marginBottom: 5}}>
                                            <button type="submit"
                                                    name={"create"}
                                                    className="btn btn-primary"
                                                    disabled={me.out && this.state.myGroup.state !== ENDED && this.state.myGroup.state !== TERMINATE}
                                            >Créer
                                            </button>
                                            {this.state.aiPlayer !== '' && <button type="button"
                                                                                   style={{marginLeft: 5}}
                                                                                   name={"play"}
                                                                                   onClick={this.createGroup}
                                                                                   className="btn btn-secondary"
                                                                                   disabled={me.out && this.state.myGroup.state !== ENDED && this.state.myGroup.state !== TERMINATE}
                                            >Jouer contre un IA</button>}
                                        </div>
                                        <div>
                                            <button type="button"
                                                    disabled={me.out && this.state.myGroup.state !== ENDED && this.state.myGroup.state !== TERMINATE}
                                                    className="btn btn-primary"
                                                    name={"join"}
                                                    onClick={this.joinGroup}>Rejoindre
                                            </button>
                                            <div style={{float: 'right'}}>
                                                <button type="button"
                                                        className="btn btn-danger"
                                                        style={{marginRight: 5}}
                                                        onClick={this.leaveGroup}>Quitter
                                                </button>
                                                {this.state.myGroup.owner === this.state.playerName
                                                    && <button type="button"
                                                               className="btn btn-danger"
                                                               style={{marginRight: 5}}
                                                               disabled={me.out && this.state.myGroup.state !== ENDED && this.state.myGroup.state !== TERMINATE}
                                                               onClick={this.removeGroup}>Supprimer</button>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div id={"messages"} className="tab-pane container fade in">
                                    <Messages socket={this.socket}
                                              sender={this.state.playerName}
                                              group={this.state.groupName}
                                              messages={this.state.messages}/>
                                </div>
                            </div>
                            {(this.state.myGroup.state === ENDED || (!!this.state.myGroup.state && this.state.myGroup.state !== PENDING)) &&
                                <div style={{marginTop: 10, marginBottom: 10}}>
                                    <button type="button"
                                            className="btn btn-success btn-block"
                                            disabled={this.state.aiPlayer === 'Observateur'}
                                            onClick={this.handleUpdate}>{this.state.myGroup.state === TERMINATE || this.state.myGroup.state === ENDED ? 'Recommencer' : 'Rafraichir'}</button>
                                </div>}
                            <label><input type={"checkbox"}
                                          name={"enableAudio"}
                                          checked={this.state.enableAudio}
                                          onChange={this.handleChange}/> Désactiver le son</label>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-3 d-none d-lg-block">
                                    <Deck title="Banque"
                                          count={this.state.deck}
                                          color="blue"
                                          style={{cursor: 'pointer'}}
                                          point={me.point}
                                          showPoints={true}
                                          onClick={this.handleDrawn}
                                    />
                                </div>
                                <div className="col-md-9">
                                    <Players currentPlayer={this.state.currentPlayer}
                                             players={players}
                                             watchers={watchers}
                                             playerName={this.state.playerName}
                                             myIndex={myIndex}
                                    />
                                    <div className={"hand-" + players.length} style={{position: 'relative'}}>
                                        {<Table playerCount={players.length}
                                                topCard={this.state.topCard}
                                                tables={this.state.tables}/>}
                                    </div>
                                    {(!!this.state.color || this.state.cmd === ASK) &&
                                        <div className={"hand-" + players.length} style={{position: 'relative'}}>
                                            {!!this.state.color && <Color color={this.state.color}
                                                                          width={50}
                                                                          height={50}/>}
                                            {this.state.cmd === ASK && <AskColor onClick={this.handleAsk}/>}
                                        </div>}
                                    <div className={"d-sm-block d-md-none hand-" + players.length}
                                         style={{position: 'relative'}}>
                                        <span
                                            style={{color: me.point < 50 ? 'green' : (me.point < 100 ? 'orange' : 'red')}}
                                            className="point">Mes Points : {me.point}</span>
                                    </div>
                                    <div className={"hand-" + players.length}
                                         style={{position: 'relative'}}>
                                        <label><input name="multiple"
                                                      type="checkbox"
                                                      disabled={this.state.cmd !== PLAY}
                                                      onChange={this.handleChange}
                                                      checked={this.state.multiple}/> Selection multiple</label>
                                        {<button type={"button"} className={"btn btn-primary d-sm-block d-md-none"}
                                                 style={{float: 'right', marginTop: -5, zIndex: 0}}
                                                 disabled={this.state.cmd !== PLAY}
                                                 onClick={this.handleDrawn}>Banque ({this.state.deck})</button>}
                                    </div>
                                    <div className={"hand-" + players.length}
                                         style={{float: 'right', position: 'relative'}}>
                                        <button className={"btn btn-primary"}
                                                style={{marginTop: -64, marginRight: 5, zIndex: 0}}
                                                onClick={(e) => {
                                                    let hands = this.state.hands;
                                                    let asc = this.state.asc;
                                                    this.setState({
                                                        hands: hands.sort((a, b) => this.state.asc ? a.index - b.index : b.index - a.index),
                                                        asc: !asc
                                                    });
                                                }}>{this.state.asc ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-sort-up"
                                                 viewBox="0 0 16 16">
                                                <path
                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                          fill="currentColor" className="bi bi-sort-down"
                                                          viewBox="0 0 16 16">
                                                <path
                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                            </svg>}</button>
                                    </div>
                                    <Hands playerCount={players.length}
                                           out={me.out}
                                           multiple={this.state.multiple}
                                           disabled={this.state.cmd !== PLAY}
                                           hands={this.state.hands}
                                           onSelect={this.handleSelect}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Sounder enabled={this.state.enableAudio} audio={this.state.color}/>
            </>
        )
            ;
    }
}

export default App;
