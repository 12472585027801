import React from "react";
import Card, {getCard} from "./Card";
import './Table.css'

export default class Table extends React.Component {

    render() {
        let tables = (this.props.tables || []).map(c => getCard(c));
        let topCard = getCard(this.props.topCard);
        if (tables.length === 0 && topCard && !!topCard.number) {
            tables.push(topCard);
        }
        return (
            <div className={"Table"} style={{...this.props.style}}>
                {
                    tables.map((card, index) => {
                        return <Card key={index} card={card} style={{zIndex: index, top: 0, left: 5 * index}}/>
                    })
                }
            </div>
        );
    }
}