import React from "react";

import HideHands from "./HideHands";
import './Players.css';

export default class Players extends React.Component {

    render() {
        let players = this.props.players || [];
        let myIndex = this.props.myIndex;
        if (myIndex === -1) {
            myIndex = 0;
        }
        let myPlayers = [];
        for (let i = 0; i < players.length; i++) {
            myPlayers.push(players[(myIndex + i) % players.length]);
        }
        let playerCount = players.length;

        return (
            <div className={"players-" + playerCount} style={{position: 'relative', ...this.props.style}}>
                {
                    myPlayers.map((player, index) => {
                        if (player.name === this.props.playerName) {
                            return null;
                        }
                        return <HideHands className={"player player-" + (index + 1) + "-" + playerCount} key={player.name}
                                          player={player}
                                          currentPlayer={this.props.currentPlayer}
                                          color={player.out ? "red" : "blue"}/>
                    })
                }
            </div>
        );
    }
}