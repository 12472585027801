import React from "react";

function Diamonds(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
            <path fill="#E70020" d="M452.17 256L256 512 59.83 256 256 0z"></path>
            <path fill="#990013" d="M452.17 256L256 512 256 0z"></path>
        </svg>
    );
}

export default Diamonds;
