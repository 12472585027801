import React from "react";

import PIQUE from './audios/PIQUE.mp3';
import COEUR from './audios/COEUR.mp3';
import TREFLE from './audios/TREFLE.mp3';
import CARREAU from './audios/CARREAU.mp3';

const AUDIOS = {
    'PIQUE': PIQUE,
    'COEUR': COEUR,
    'TREFLE': TREFLE,
    'CARREAU': CARREAU,
}

const {navigator} = window;

export default class Sounder extends React.Component {

    getSound() {
        let audio = this.props.audio;
        return AUDIOS[audio];
    }

    componentDidUpdate(prevProps) {
        if(navigator.userAgent.indexOf("Safari/") >= 0 && navigator.userAgent.indexOf("Chrome/") < 0) {
            return
        }
        if (this.props.enabled && this.props.audio && this.props.audio !== prevProps.audio) {
            document.getElementById("audio").play();
        }
    }

    render() {
        return (
            <>
                {this.props.enabled && <audio id="audio" src={this.getSound()} preload="auto">
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>}
            </>
        );
    }
}