import React from "react";

import './Messages.css'

const MESSAGE = 'MESSAGE';
export default class Messages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        };
        this.handleMessage = this.handleMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    handleMessage(event) {
        event.preventDefault();
        let message = this.state.message;
        console.log('message', message);
        if (this.props.socket) {
            let data = `${this.props.group}|${MESSAGE}|${this.props.sender}|${message}`;
            this.props.socket.send(data);
            this.setState({
                message: ''
            });
        } else {
            console.log('socket is null');
        }
    }

    render() {
        let messages = this.props.messages || []
        return (
            <div>
                <div className="Messages" style={{overflow: 'auto'}}>
                    <h2>Messages</h2>
                    {
                        messages.map((msg, index) => {
                            return <div key={messages.length - index}>
                                <span>{msg}</span>
                                <hr/>
                            </div>
                        })
                    }
                </div>
                <form onSubmit={this.handleMessage}>
                    <input type="text"
                           className="form-control"
                           id="message"
                           name="message"
                           value={this.state.message}
                           placeholder="Message"
                           onChange={this.handleChange}
                           required/>
                </form>
            </div>
        );
    }
}