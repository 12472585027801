import React from "react";
import Spades from "./img/Spades";
import Heart from "./img/Heart";
import Clubs from "./img/Clubs";
import Diamonds from "./img/Diamonds";

export default class AskColor extends React.Component {

    render() {
        return (
            <div>
                <Spades onClick={() => this.props.onClick('PIQUE')}
                       width={50}
                       height={50}
                       style={{margin: 10, cursor: 'pointer'}} color={"PIQUE"} />
                <Heart onClick={() => this.props.onClick('COEUR')}
                       width={50}
                       height={50}
                       style={{margin: 10, cursor: 'pointer'}} color={"COEUR"} />
                <Clubs onClick={() => this.props.onClick('TREFLE')}
                       width={50}
                       height={50}
                       style={{margin: 10, cursor: 'pointer'}} color={"TREFLE"} />
                <Diamonds onClick={() => this.props.onClick('CARREAU')}
                       width={50}
                       height={50}
                       style={{margin: 10, cursor: 'pointer'}} color={"CARREAU"} />
            </div>
        );
    }
}