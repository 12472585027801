import React from "react";

function Clubs(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
            <path
                fill="#3C3A41"
                d="M497.95 343.61c-6.26 36.31-25.72 68.13-54.78 89.62-29.27 21.63-64.75 30.2-99.9 24.14a128.568 128.568 0 01-34.24-11c3.37 10.83 7.25 17 10.6 19.36l6.37 4.48V512H186v-41.79l6.37-4.48c3.35-2.36 7.23-8.53 10.6-19.36-10.79 5.23-22.3 8.94-34.24 11-35.15 6.06-70.63-2.51-99.9-24.14-29.06-21.49-48.52-53.31-54.77-89.62-6.26-36.31 1.41-72.81 21.6-102.79 20.33-30.18 50.89-50.14 86.05-56.2 2.08-.36 4.17-.67 6.26-.92a128.41 128.41 0 01-10.36-50.6C117.61 59.71 179.69 0 256 0s138.39 59.71 138.39 133.1c0 17.52-3.54 34.66-10.36 50.6 2.1.25 4.18.56 6.26.92 35.16 6.06 65.72 26.02 86.05 56.2 20.19 29.98 27.87 66.48 21.61 102.79z"
            ></path>
            <path
                fill="#17171A"
                d="M497.95 343.61c-6.26 36.31-25.72 68.13-54.78 89.62-29.27 21.63-64.75 30.2-99.9 24.14a128.568 128.568 0 01-34.24-11c3.37 10.83 7.25 17 10.6 19.36l6.37 4.48V512h-70V0c76.31 0 138.39 59.71 138.39 133.1 0 17.52-3.54 34.66-10.36 50.6 2.1.25 4.18.56 6.26.92 35.16 6.06 65.72 26.02 86.05 56.2 20.19 29.98 27.87 66.48 21.61 102.79z"
            ></path>
        </svg>
    );
}

export default Clubs;
