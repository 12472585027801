import React from "react";

function BackBlue(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            version="1"
            viewBox="0 0 167.18 242.86"
        >
            <g
                fill="none"
                stroke="#17067a"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.318"
            >
                <path
                    fill="#fff"
                    stroke="#000"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth="0.5"
                    d="M166.93 235.74c0 3.774-3.086 6.87-6.87 6.87H7.21c-3.775 0-6.86-3.096-6.86-6.87V7.31C.35 3.53 3.434.44 7.21.44h152.85c3.784 0 6.87 3.09 6.87 6.87v228.43z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M7.647 1.87c-3.213 0-5.801 2.515-5.801 5.642v227.92c0 3.126 2.588 5.642 5.801 5.642h152.07c3.215 0 5.8-2.516 5.8-5.642V7.512c0-3.127-2.585-5.643-5.8-5.643H7.647z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeWidth="2.852"
                    d="M7.647 1.87c-3.213 0-5.801 2.515-5.801 5.642v227.92c0 3.126 2.588 5.642 5.801 5.642h152.07c3.215 0 5.8-2.516 5.8-5.642V7.512c0-3.127-2.585-5.643-5.8-5.643H7.647z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M9.29 7.405c-.81 0-1.461.634-1.461 1.42v225.3c0 .787.65 1.42 1.46 1.42h148.42c.81 0 1.462-.633 1.462-1.42V8.826c0-.786-.652-1.42-1.462-1.42H9.29z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeWidth="2.678"
                    d="M9.29 7.405c-.81 0-1.461.634-1.461 1.42v225.3c0 .787.65 1.42 1.46 1.42h148.42c.81 0 1.462-.633 1.462-1.42V8.826c0-.786-.652-1.42-1.462-1.42H9.29z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M38.657 37.114a.693.693 0 00-.704.685v168.07c0 .38.314.686.704.686h88.201a.692.692 0 00.704-.686V37.8a.692.692 0 00-.704-.685H38.657z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeWidth="1.642"
                    d="M38.657 37.114a.693.693 0 00-.704.685v168.07c0 .38.314.686.704.686h88.201a.692.692 0 00.704-.686V37.8a.692.692 0 00-.704-.685H38.657v0z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M52.62 43.074c-4.62 0-8.34 3.619-8.34 8.112v140.57c0 4.494 3.72 8.113 8.34 8.113h59.907c4.62 0 8.34-3.619 8.34-8.113V51.186c0-4.493-3.72-8.112-8.34-8.112H52.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeLinecap="butt"
                    strokeMiterlimit="10"
                    strokeWidth="2.065"
                    d="M52.62 43.074c-4.62 0-8.34 3.619-8.34 8.112v140.57c0 4.494 3.72 8.113 8.34 8.113h59.907c4.62 0 8.34-3.619 8.34-8.113V51.186c0-4.493-3.72-8.112-8.34-8.112H52.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M53.547 49.537c-1.554 0-2.805 1.217-2.805 2.729v138.42c0 1.513 1.251 2.73 2.805 2.73h57.682c1.556 0 2.809-1.217 2.809-2.73V52.266c0-1.512-1.253-2.729-2.809-2.729H53.547z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeLinecap="butt"
                    strokeMiterlimit="10"
                    strokeWidth="1.798"
                    d="M53.547 49.537c-1.554 0-2.805 1.217-2.805 2.729v138.42c0 1.513 1.251 2.73 2.805 2.73h57.682c1.556 0 2.809-1.217 2.809-2.73V52.266c0-1.512-1.253-2.729-2.809-2.729H53.547z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#17067a"
                    fillRule="evenodd"
                    stroke="none"
                    d="M9.415 9.032h6.662L9.48 15.435l-.064-6.403z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeWidth="0.864"
                    d="M9.415 9.032h6.662L9.48 15.435l-.064-6.403h0z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#17067a"
                    fillRule="evenodd"
                    stroke="none"
                    d="M157.48 9.115h-6.662l6.598 6.402.064-6.402z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeWidth="0.864"
                    d="M157.48 9.115h-6.662l6.598 6.402.064-6.402z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#17067a"
                    fillRule="evenodd"
                    stroke="none"
                    d="M157.6 234.12h-6.663l6.6-6.401.063 6.401z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeWidth="0.864"
                    d="M157.6 234.12h-6.663l6.6-6.401.063 6.401z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#17067a"
                    fillRule="evenodd"
                    stroke="none"
                    d="M9.41 234.08h6.662l-6.598-6.402-.065 6.402z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeWidth="0.864"
                    d="M9.41 234.08h6.662l-6.598-6.402-.065 6.402z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.966 130.63v6.134l22.428-11.47v-6.285L11.966 130.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.966 130.63v6.134l22.428-11.47v-6.285L11.966 130.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.08 142.23v6.134l22.428-11.472v-6.283l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.08 142.23v6.134l22.428-11.472v-6.283l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.854 136.8v-6.134l22.427 11.471v6.284l-22.427-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.854 136.8v-6.134l22.427 11.471v6.284l-22.427-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.13 118.87v6.134l22.428-11.472v-6.283L12.13 118.87z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.13 118.87v6.134l22.428-11.472v-6.283L12.13 118.87z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.023 125.26v-6.133l22.429 11.471v6.284L12.023 125.26z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.023 125.26v-6.133l22.429 11.471v6.284L12.023 125.26z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.016 107.27v6.134l22.428-11.47v-6.285L12.016 107.27z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.016 107.27v6.134l22.428-11.47v-6.285L12.016 107.27z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.904 113.44v-6.133l22.428 11.472v6.282L11.904 113.44z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.904 113.44v-6.133l22.428 11.472v6.282L11.904 113.44z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.073 101.9v-6.133l22.428 11.472v6.282l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.073 101.9v-6.133l22.428 11.472v6.282l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.111 153.94v6.133l22.428-11.47v-6.284l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.111 153.94v6.133l22.428-11.47v-6.284l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.885 148.5v-6.133l22.428 11.471v6.285L11.885 148.5z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.885 148.5v-6.133l22.428 11.471v6.285L11.885 148.5z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.883 72.185v6.133l22.428-11.47v-6.284L11.883 72.185z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.883 72.185v6.133l22.428-11.47v-6.284L11.883 72.185z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.997 83.783v6.135l22.429-11.472v-6.284l-22.429 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.997 83.783v6.135l22.429-11.472v-6.284l-22.429 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.771 78.349v-6.134L34.2 83.685v6.285L11.771 78.349z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.771 78.349v-6.134L34.2 83.685v6.285L11.771 78.349z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.048 60.424v6.135l22.428-11.472v-6.284L12.048 60.424z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.048 60.424v6.135l22.428-11.472v-6.284L12.048 60.424z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.94 66.812v-6.133l22.429 11.47v6.284L11.94 66.812z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.94 66.812v-6.133l22.429 11.47v6.284L11.94 66.812z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.933 48.826v6.133L34.36 43.49v-6.284L11.933 48.826z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.933 48.826v6.133L34.36 43.49v-6.284L11.933 48.826z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.822 54.99v-6.133l22.428 11.47v6.284l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.822 54.99v-6.133l22.428 11.47v6.284l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.99 43.453V37.32l22.429 11.47v6.284L11.99 43.454z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.99 43.453V37.32l22.429 11.47v6.284L11.99 43.454z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.029 95.489v6.133l22.428-11.47v-6.284L12.029 95.49z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.029 95.489v6.133l22.428-11.47v-6.284L12.029 95.49z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.803 90.053v-6.134l22.428 11.472v6.283l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.803 90.053v-6.134l22.428 11.472v6.283l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.966 188.92v6.135l22.428-11.473v-6.285L11.966 188.92z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.966 188.92v6.135l22.428-11.473v-6.285L11.966 188.92z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.08 200.52v6.133l22.428-11.471v-6.283l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.08 200.52v6.133l22.428-11.471v-6.283l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.854 195.08v-6.136l22.427 11.472v6.286L11.854 195.08z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.854 195.08v-6.136l22.427 11.472v6.286L11.854 195.08z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.13 177.16v6.133l22.428-11.471v-6.283l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.13 177.16v6.133l22.428-11.471v-6.283l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.023 183.55v-6.135l22.429 11.473v6.284L12.023 183.55z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.023 183.55v-6.135l22.429 11.473v6.284L12.023 183.55z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.016 165.56v6.133l22.428-11.473v-6.281l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.016 165.56v6.133l22.428-11.473v-6.281l-22.428 11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M11.904 171.72v-6.134l22.428 11.471v6.283l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M11.904 171.72v-6.134l22.428 11.471v6.283l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M12.073 160.19v-6.134l22.428 11.471v6.284l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M12.073 160.19v-6.134l22.428 11.471v6.284l-22.428-11.62z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.94 113.32v-6.136l22.427 11.478v6.286L131.94 113.32z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.94 113.32v-6.136l22.427 11.478v6.286L131.94 113.32z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M132.05 101.72v-6.137l22.43 11.48v6.287l-22.43-11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M132.05 101.72v-6.137l22.43 11.48v6.287l-22.43-11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.83 107.16v6.136l22.427-11.477v-6.288L131.83 107.16z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.83 107.16v6.136l22.427-11.477v-6.288L131.83 107.16z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M132.1 125.09v-6.138l22.431 11.478v6.29L132.1 125.09z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M132.1 125.09v-6.138l22.431 11.478v6.29L132.1 125.09z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.99 118.7v6.136l22.428-11.477v-6.287L131.99 118.7z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.99 118.7v6.136l22.428-11.477v-6.287L131.99 118.7z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.99 136.69v-6.137l22.428 11.48v6.286L131.99 136.69z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.99 136.69v-6.137l22.428 11.48v6.286L131.99 136.69z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.88 130.53v6.136l22.43-11.476V118.9l-22.43 11.628z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.88 130.53v6.136l22.43-11.476V118.9l-22.43 11.628z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M132.04 142.07v6.138l22.428-11.478v-6.29l-22.428 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M132.04 142.07v6.138l22.428-11.478v-6.29l-22.428 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M132.08 90.005v-6.138l22.428 11.478v6.287L132.08 90.005z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M132.08 90.005v-6.138l22.428 11.478v6.287L132.08 90.005z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.86 95.443v6.138l22.43-11.479v-6.287l-22.43 11.628z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.86 95.443v6.138l22.43-11.479v-6.287l-22.43 11.628z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.85 171.81v-6.139l22.43 11.48v6.286l-22.43-11.627z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.85 171.81v-6.139l22.43 11.48v6.286l-22.43-11.627z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.97 160.2v-6.139l22.428 11.478v6.288L131.97 160.2z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.97 160.2v-6.139l22.428 11.478v6.288L131.97 160.2z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.74 165.64v6.138l22.428-11.478v-6.288L131.74 165.64z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.74 165.64v6.138l22.428-11.478v-6.288L131.74 165.64z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M132.02 183.57v-6.137l22.429 11.477v6.29l-22.429-11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M132.02 183.57v-6.137l22.429 11.477v6.29l-22.429-11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.91 177.18v6.137l22.428-11.477v-6.29l-22.428 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.91 177.18v6.137l22.428-11.477v-6.29l-22.428 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.9 195.18v-6.137l22.429 11.477v6.287L131.9 195.18z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.9 195.18v-6.137l22.429 11.477v6.287L131.9 195.18z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.79 189.01v6.136l22.428-11.48v-6.286l-22.428 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.79 189.01v6.136l22.428-11.48v-6.286l-22.428 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.96 200.56v6.137l22.428-11.478v-6.288L131.96 200.56z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.96 200.56v6.137l22.428-11.478v-6.288L131.96 200.56z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M132 148.49v-6.136l22.428 11.477v6.287L132 148.49z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M132 148.49v-6.136l22.428 11.477v6.287L132 148.49z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.77 153.93v6.137l22.427-11.48v-6.286L131.77 153.93z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.77 153.93v6.137l22.427-11.48v-6.286L131.77 153.93z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.94 55.001v-6.139l22.427 11.48v6.287L131.94 55.002z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.94 55.001v-6.139l22.427 11.48v6.287L131.94 55.002z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M132.05 43.395v-6.138l22.43 11.478v6.288l-22.43-11.628z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M132.05 43.395v-6.138l22.43 11.478v6.288l-22.43-11.628z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.83 48.833v6.138l22.427-11.478v-6.288L131.83 48.833z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.83 48.833v6.138l22.427-11.478v-6.288L131.83 48.833z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M132.1 66.767v-6.136l22.431 11.478v6.286L132.1 66.767z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M132.1 66.767v-6.136l22.431 11.478v6.286L132.1 66.767z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.99 60.378v6.137l22.428-11.48v-6.287l-22.428 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.99 60.378v6.137l22.428-11.48v-6.287l-22.428 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.99 78.374v-6.137l22.428 11.477v6.288L131.99 78.374z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.99 78.374v-6.137l22.428 11.477v6.288L131.99 78.374z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M131.88 72.207v6.137l22.43-11.479v-6.288l-22.43 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M131.88 72.207v6.137l22.43-11.479v-6.288l-22.43 11.63z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M132.04 83.75v6.137l22.428-11.477v-6.287L132.04 83.75z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M132.04 83.75v6.137l22.428-11.477v-6.287L132.04 83.75z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M68.547 11.51h-6.29l11.765 21.815h6.446L68.548 11.51z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M68.547 11.51h-6.29l11.765 21.815h6.446L68.548 11.51z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M56.652 11.622h-6.29l11.764 21.815h6.445L56.65 11.622z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M56.652 11.622h-6.29l11.764 21.815h6.445L56.65 11.622z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M62.227 11.402h6.29L56.753 33.217h-6.444l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M62.227 11.402h6.29L56.753 33.217h-6.444l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M80.609 11.669h-6.291l11.764 21.815h6.445L80.61 11.669z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M80.609 11.669h-6.291l11.764 21.815h6.445L80.61 11.669z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M74.059 11.566h6.291L68.586 33.381h-6.445l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M74.059 11.566h6.291L68.586 33.381h-6.445l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M116.46 11.66h-6.29l11.765 21.815h6.444L116.46 11.66z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M116.46 11.66h-6.29l11.765 21.815h6.444L116.46 11.66z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M92.504 11.558h-6.29l11.765 21.817h6.446l-11.92-21.817z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M92.504 11.558h-6.29l11.765 21.817h6.446l-11.92-21.817z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M86.184 11.45h6.29L80.71 33.266h-6.445L86.183 11.45z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M86.184 11.45h6.29L80.71 33.266h-6.445L86.183 11.45z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M104.56 11.771h-6.29l11.765 21.815h6.443L104.56 11.771z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M104.56 11.771h-6.29l11.765 21.815h6.443L104.56 11.771z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M98.016 11.615h6.291L92.541 33.43h-6.443l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M98.016 11.615h6.291L92.541 33.43h-6.443l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M110.14 11.55h6.291l-11.764 21.817h-6.445L110.14 11.55z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M110.14 11.55h6.291l-11.764 21.817h-6.445L110.14 11.55z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M128.49 11.718h-6.29l11.765 21.815h6.443L128.49 11.718z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M128.49 11.718h-6.29l11.765 21.815h6.443L128.49 11.718z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M121.97 11.714h6.29L116.494 33.53h-6.445l11.92-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M121.97 11.714h6.29L116.494 33.53h-6.445l11.92-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M44.648 11.651h-6.29l11.765 21.815h6.443L44.648 11.651z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M44.648 11.651h-6.29l11.765 21.815h6.443L44.648 11.651z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M32.868 11.435h-6.29l11.765 21.816h6.444L32.868 11.435z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M32.868 11.435h-6.29l11.765 21.816h6.444L32.868 11.435z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M50.223 11.431h6.29L44.748 33.247h-6.445l11.92-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M50.223 11.431h6.29L44.748 33.247h-6.445l11.92-21.816h0z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M20.957 11.435h-6.29l11.764 21.816h6.444L20.957 11.435z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M20.957 11.435h-6.29l11.764 21.816h6.444L20.957 11.435z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M38.341 11.435h6.29L32.866 33.251h-6.443L38.34 11.435z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M38.341 11.435h6.29L32.866 33.251h-6.443L38.34 11.435z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M26.585 11.435h6.29L21.114 33.251h-6.446l11.918-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M26.585 11.435h6.29L21.114 33.251h-6.446l11.918-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M140.38 11.606h-6.291l11.766 21.817h6.443L140.38 11.606z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M140.38 11.606h-6.291l11.766 21.817h6.443L140.38 11.606z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M134.06 11.498h6.29l-11.765 21.815h-6.447l11.922-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M134.06 11.498h6.29l-11.765 21.815h-6.447l11.922-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M145.89 11.663h6.29l-11.763 21.815h-6.445l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M145.89 11.663h6.29l-11.763 21.815h-6.445l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M68.889 209.93h-6.29l11.763 21.815h6.446L68.89 209.93z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M68.889 209.93h-6.29l11.763 21.815h6.446L68.89 209.93z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M56.994 210.04h-6.292l11.766 21.816h6.445L56.995 210.04z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M56.994 210.04h-6.292l11.766 21.816h6.445L56.995 210.04z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M62.567 209.83h6.292l-11.764 21.814h-6.446l11.918-21.814z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M62.567 209.83h6.292l-11.764 21.814h-6.446l11.918-21.814z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M80.949 210.09h-6.29l11.764 21.814h6.445L80.95 210.09z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M80.949 210.09h-6.29l11.764 21.814h6.445L80.95 210.09z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M74.4 209.99h6.29l-11.764 21.816h-6.445l11.92-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M74.4 209.99h6.29l-11.764 21.816h-6.445l11.92-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M116.8 210.08h-6.291l11.766 21.815h6.445L116.8 210.08z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M116.8 210.08h-6.291l11.766 21.815h6.445L116.8 210.08z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M92.846 209.98h-6.291l11.764 21.816h6.446L92.846 209.98z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M92.846 209.98h-6.291l11.764 21.816h6.446L92.846 209.98z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M86.523 209.87h6.292L81.05 231.685h-6.445l11.917-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M86.523 209.87h6.292L81.05 231.685h-6.445l11.917-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M104.91 210.19h-6.293l11.766 21.815h6.445L104.91 210.19z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M104.91 210.19h-6.293l11.766 21.815h6.445L104.91 210.19z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M98.355 210.04h6.291L92.88 231.854h-6.443l11.918-21.814z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M98.355 210.04h6.291L92.88 231.854h-6.443l11.918-21.814z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M110.48 209.97h6.29l-11.762 21.818h-6.446l11.918-21.818z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M110.48 209.97h6.29l-11.762 21.818h-6.446l11.918-21.818z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M128.65 210.21h-6.291l11.766 21.816h6.443L128.65 210.21z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M128.65 210.21h-6.291l11.766 21.816h6.443L128.65 210.21z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M122.31 210.14h6.29l-11.765 21.815h-6.445l11.92-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M122.31 210.14h6.29l-11.765 21.815h-6.445l11.92-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M44.988 210.07h-6.29l11.764 21.815h6.445L44.989 210.07z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M44.988 210.07h-6.29l11.764 21.815h6.445L44.989 210.07z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M32.999 210.12h-6.291l11.765 21.816h6.444L33 210.12z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M32.999 210.12h-6.291l11.765 21.816h6.444L33 210.12z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M50.564 209.85h6.29L45.09 231.665h-6.444l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M50.564 209.85h6.29L45.09 231.665h-6.444l11.918-21.815z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M140.55 210.1h-6.29l11.763 21.817h6.446L140.55 210.1z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M140.55 210.1h-6.29l11.763 21.817h6.446L140.55 210.1z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M134.23 209.99h6.29l-11.764 21.816h-6.447l11.92-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M134.23 209.99h6.29l-11.764 21.816h-6.447l11.92-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M146.06 210.15h6.29l-11.765 21.816h-6.444l11.919-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M146.06 210.15h6.29l-11.765 21.816h-6.444l11.919-21.816z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M38.573 209.9h6.291l-11.765 21.818h-6.444L38.572 209.9z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M38.573 209.9h6.291l-11.765 21.818h-6.444L38.572 209.9z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#313390"
                    fillRule="evenodd"
                    stroke="none"
                    d="M20.995 210.15h-6.291l11.765 21.815h6.445L20.996 210.15z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M20.995 210.15h-6.291l11.765 21.815h6.445L20.996 210.15z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    stroke="none"
                    d="M26.569 209.93h6.291l-11.764 21.817H14.65l11.919-21.817z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    strokeMiterlimit="10"
                    strokeWidth="1.317"
                    d="M26.569 209.93h6.291l-11.764 21.817H14.65l11.919-21.817z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
                <path
                    stroke="#000"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth="0.5"
                    d="M166.83 235.54c0 3.774-3.086 6.87-6.871 6.87H7.109c-3.775 0-6.86-3.096-6.86-6.87V7.11c0-3.78 3.085-6.87 6.86-6.87h152.85c3.785 0 6.871 3.09 6.871 6.87v228.43z"
                    transform="matrix(1.0001 0 0 .99809 -.008 .232)"
                ></path>
            </g>
        </svg>
    );
}

export default BackBlue;
