import React from "react";

function BackRed(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            version="1"
            viewBox="0 0 167.09 242.67"
        >
            <g fill="#fff" stroke="#000" strokeWidth="0.998">
                <path
                    strokeWidth="0.499"
                    d="M166.84 235.55c0 3.777-3.086 6.87-6.87 6.87H7.12c-3.775 0-6.861-3.093-6.861-6.87V7.12c0-3.777 3.086-6.87 6.86-6.87h152.85c3.785 0 6.871 3.093 6.871 6.87v228.43z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M119.84 46.616V9.319H82.814v37.297h37.026z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M156.84 46.616V9.319h-37.006v37.297h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M156.84 83.913V46.616h-37.006v37.297h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M119.84 83.913V46.616H82.814v37.297h37.026z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M45.804 121.21V83.914H8.798v37.296h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M82.811 121.21V83.914H45.804v37.296h37.007z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M119.84 121.21V83.914H82.814v37.296h37.026z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M156.84 121.21V83.914h-37.006v37.296h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M82.811 83.913V46.616H45.804v37.297h37.007z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M45.804 83.913V46.616H8.798v37.297h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M45.804 46.616V9.319H8.798v37.297h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M82.811 46.616V9.319H45.804v37.297h37.007z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M82.811 233.12v-37.307H45.804v37.307h37.007z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M45.804 233.12v-37.307H8.798v37.307h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M45.804 195.81v-37.297H8.798v37.297h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M82.811 195.81v-37.297H45.804v37.297h37.007z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M156.84 158.52v-37.307h-37.006v37.307h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M119.84 158.52v-37.307H82.814v37.307h37.026z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M82.811 158.52v-37.307H45.804v37.307h37.007z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M45.804 158.52v-37.307H8.798v37.307h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M119.84 195.81v-37.297H82.814v37.297h37.026z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M156.84 195.81v-37.297h-37.006v37.297h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M156.84 233.12v-37.307h-37.006v37.307h37.006z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M119.84 233.12v-37.307H82.814v37.307h37.026zM115.2 9.32l4.633 4.647-32.395 32.65-4.632-4.67L115.2 9.32z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M152.21 9.32l4.632 4.647-32.373 32.65-4.633-4.67L152.21 9.32"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M124.47 46.616l-4.633 4.668 32.374 32.629 4.632-4.67-32.373-32.627z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M87.443 46.616l-4.632 4.668 32.394 32.629 4.633-4.67-32.395-32.627"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M41.173 83.913l4.631 4.657-32.384 32.64-4.622-4.648 32.375-32.648z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M78.199 83.913l4.612 4.657-32.375 32.64-4.632-4.648 32.395-32.648"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M115.2 83.913l4.633 4.657-32.395 32.64-4.632-4.648L115.2 83.914z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M152.21 83.913l4.632 4.657-32.373 32.64-4.633-4.648 32.374-32.648"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M50.437 46.616l-4.632 4.668L78.2 83.913l4.612-4.67-32.375-32.627z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M13.42 46.616l-4.622 4.668 32.375 32.629 4.631-4.67L13.42 46.616"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M41.173 9.32l4.631 4.647-32.384 32.65-4.622-4.67L41.173 9.32z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M78.199 9.32l4.612 4.647-32.375 32.65-4.632-4.67L78.198 9.32"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M50.437 233.12l-4.632-4.669L78.2 195.813l4.612 4.668-32.375 32.64z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M13.42 233.12l-4.622-4.669 32.375-32.638 4.631 4.668-32.384 32.64"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M41.173 195.81l4.631-4.669-32.384-32.628-4.622 4.669 32.375 32.628z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M78.199 195.81l4.612-4.669-32.375-32.628-4.632 4.669 32.395 32.628"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M124.47 158.52l-4.633-4.658 32.374-32.648 4.632 4.669-32.373 32.638z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M87.443 158.52l-4.632-4.658 32.394-32.648 4.633 4.669-32.395 32.638"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M50.437 158.52l-4.632-4.658L78.2 121.214l4.612 4.669-32.375 32.638z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M13.42 158.52l-4.622-4.658 32.375-32.648 4.631 4.669L13.42 158.52"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M115.2 195.81l4.633-4.669-32.395-32.628-4.632 4.669L115.2 195.81z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M152.21 195.81l4.632-4.669-32.373-32.628-4.633 4.669 32.374 32.628"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M124.47 233.12l-4.633-4.669 32.374-32.638 4.632 4.668-32.373 32.64z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M87.443 233.12l-4.632-4.669 32.394-32.638 4.633 4.668-32.395 32.64"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M87.443 9.32l-4.632 4.647 32.394 32.65 4.633-4.67L87.443 9.32z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M101.32 9.32L82.812 27.971l18.508 18.644 18.519-18.644L101.32 9.32z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M124.47 9.32l-4.633 4.647 32.374 32.65 4.632-4.67L124.47 9.32"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M138.33 9.32l-18.488 18.652 18.488 18.644 18.518-18.644L138.33 9.32"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M152.21 46.616l4.632 4.668-32.373 32.629-4.633-4.67 32.374-32.627z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M138.33 46.616l18.518 18.653-18.518 18.644-18.488-18.644 18.488-18.653z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M115.2 46.616l4.633 4.668-32.395 32.629-4.632-4.67L115.2 46.616"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M101.32 46.616l18.519 18.653-18.519 18.644-18.508-18.644 18.508-18.653"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M13.42 83.913L8.798 88.57l32.375 32.64 4.631-4.648L13.42 83.914z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 83.913L8.798 102.565l18.519 18.644 18.487-18.644-18.487-18.652z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M50.437 83.913l-4.632 4.657L78.2 121.21l4.612-4.648-32.375-32.648"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M64.323 83.913l-18.519 18.652 18.519 18.644 18.488-18.644-18.488-18.652"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M87.443 83.913l-4.632 4.657 32.394 32.64 4.633-4.648-32.395-32.648z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M101.32 83.913l-18.508 18.652 18.508 18.644 18.519-18.644-18.519-18.652z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M124.47 83.913l-4.633 4.657 32.374 32.64 4.632-4.648-32.373-32.648"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M138.33 83.913l-18.488 18.652 18.488 18.644 18.518-18.644-18.518-18.652"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M78.199 46.616l4.612 4.668-32.375 32.629-4.632-4.67 32.395-32.627z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M64.323 46.616l18.488 18.653-18.488 18.644-18.519-18.644 18.519-18.653z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M41.173 46.616l4.631 4.668L13.42 83.913l-4.622-4.67 32.375-32.627"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 46.616l18.487 18.653-18.487 18.644L8.798 65.269l18.519-18.653"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M13.42 9.32l-4.622 4.647 32.375 32.65 4.631-4.67L13.42 9.32z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 9.32L8.798 27.971l18.519 18.644 18.487-18.644L27.317 9.32z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M50.437 9.32l-4.632 4.647L78.2 46.617l4.612-4.67L50.437 9.32"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M64.323 9.32L45.804 27.971l18.519 18.644 18.488-18.644L64.323 9.32"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M78.199 233.12l4.612-4.669-32.375-32.638-4.632 4.668 32.395 32.64z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M64.323 233.12l18.488-18.664-18.488-18.643-18.519 18.643 18.519 18.664z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M41.173 233.12l4.631-4.669-32.384-32.638-4.622 4.668 32.375 32.64"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 233.12l18.487-18.664-18.487-18.643-18.519 18.643 18.519 18.664"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 195.81L8.798 177.166l18.519-18.653 18.487 18.653-18.487 18.644z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M50.437 195.81l-4.632-4.669L78.2 158.513l4.612 4.669-32.375 32.628"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M64.323 195.81l-18.519-18.644 18.519-18.653 18.488 18.653-18.488 18.644"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M152.21 158.52l4.632-4.658-32.373-32.648-4.633 4.669 32.374 32.638z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M138.33 158.52l18.518-18.653-18.518-18.653-18.488 18.653 18.488 18.653z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M115.2 158.52l4.633-4.658-32.395-32.648-4.632 4.669L115.2 158.52"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M101.32 158.52l18.519-18.653-18.519-18.653-18.508 18.653 18.508 18.653"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M78.199 158.52l4.612-4.658-32.375-32.648-4.632 4.669 32.395 32.638z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M64.323 158.52l18.488-18.653-18.488-18.653-18.519 18.653 18.519 18.653z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M41.173 158.52l4.631-4.658-32.384-32.648-4.622 4.669 32.375 32.638"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 158.52l18.487-18.653-18.487-18.653-18.519 18.653 18.519 18.653"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M87.443 195.81l-4.632-4.669 32.394-32.628 4.633 4.669-32.395 32.628z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M101.32 195.81l-18.508-18.644 18.508-18.653 18.519 18.653-18.519 18.644z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M124.47 195.81l-4.633-4.669 32.374-32.628 4.632 4.669-32.373 32.628"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M138.33 195.81l-18.488-18.644 18.488-18.653 18.518 18.653-18.518 18.644"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M152.21 233.12l4.632-4.669-32.373-32.638-4.633 4.668 32.374 32.64z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M138.33 233.12l18.518-18.664-18.518-18.643-18.488 18.643 18.488 18.664z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M115.2 233.12l4.633-4.669-32.395-32.638-4.632 4.668 32.394 32.64"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M101.32 233.12l18.519-18.664-18.519-18.643-18.508 18.643 18.508 18.664M110.58 37.279V18.057H92.061v19.222h18.519z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M147.59 37.279V18.057h-18.498v19.222h18.498zM101.32 20.97l-6.938 7.002 6.938 6.973 6.948-6.973-6.948-7.002"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M138.33 20.97l-6.928 7.002 6.928 6.973 6.947-6.973-6.947-7.002M147.59 74.586V55.364h-18.498v19.222h18.498z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M110.58 74.586V55.364H92.061v19.222h18.519zM138.33 58.277l6.947 6.993-6.947 6.982-6.928-6.982 6.928-6.993"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M101.32 58.277l6.948 6.993-6.948 6.982-6.938-6.982 6.938-6.993M36.551 111.89V92.658H18.052v19.232h18.499z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M73.567 111.89V92.658H55.059v19.232h18.508zM27.317 95.563l-6.949 7.002 6.949 7.003 6.918-7.003-6.918-7.002"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M64.323 95.563l-6.948 7.002 6.948 7.003 6.928-7.003-6.928-7.002M110.58 111.89V92.658H92.061v19.232h18.519z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M147.59 111.89V92.658h-18.498v19.232h18.498zM101.32 95.563l-6.938 7.002 6.938 7.003 6.948-7.003-6.948-7.002"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M138.33 95.563l-6.928 7.002 6.928 7.003 6.947-7.003-6.947-7.002M73.567 74.586V55.364H55.059v19.222h18.508z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M36.551 74.586V55.364H18.052v19.222h18.499zM64.323 58.277l6.928 6.993-6.928 6.982-6.948-6.982 6.948-6.993"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 58.277l6.918 6.993-6.918 6.982-6.949-6.982 6.949-6.993M36.551 37.279V18.057H18.052v19.222h18.499z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M73.567 37.279V18.057H55.059v19.222h18.508zM27.317 20.97l-6.949 7.002 6.949 6.973 6.918-6.973-6.918-7.002"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M64.323 20.97l-6.948 7.002 6.948 6.973 6.928-6.973-6.928-7.002M73.567 224.36v-19.211H55.059v19.211h18.508z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M36.551 224.36v-19.211H18.052v19.211h18.499zM64.323 221.46l6.928-7.003-6.928-6.981-6.948 6.981 6.948 7.003"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 221.46l6.918-7.003-6.918-6.981-6.949 6.981 6.949 7.003"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M73.567 187.07v-19.231H55.059v19.231h18.508z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M64.323 184.16l-6.948-6.992 6.948-7.002 6.928 7.002-6.928 6.992M147.59 149.77v-19.241h-18.498v19.241h18.498z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M110.58 149.77v-19.241H92.061v19.241h18.519zM138.33 146.86l6.947-7.002-6.947-7.002-6.928 7.002 6.928 7.002"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M101.32 146.86l6.948-7.002-6.948-7.002-6.938 7.002 6.938 7.002M73.567 149.77v-19.241H55.059v19.241h18.508z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M36.551 149.77v-19.241H18.052v19.241h18.499zM64.323 146.86l6.928-7.002-6.928-7.002-6.948 7.002 6.948 7.002"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 146.86l6.918-7.002-6.918-7.002-6.949 7.002 6.949 7.002M110.58 187.07v-19.231H92.061v19.231h18.519z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M147.59 187.07v-19.231h-18.498v19.231h18.498zM101.32 184.16l-6.938-6.992 6.938-7.002 6.948 7.002-6.948 6.992"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M138.33 184.16l-6.928-6.992 6.928-7.002 6.947 7.002-6.947 6.992M147.59 224.36v-19.211h-18.498v19.211h18.498z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M110.58 224.36v-19.211H92.061v19.211h18.519zM138.33 221.46l6.947-7.003-6.947-6.981-6.928 6.981 6.928 7.003"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M101.32 221.46l6.948-7.003-6.948-6.981-6.938 6.981 6.938 7.003"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M13.42 195.81l-4.622-4.669 32.375-32.628 4.631 4.669L13.42 195.81z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fillRule="evenodd"
                    stroke="none"
                    d="M36.551 187.07v-19.231H18.052v19.231h18.499z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="#d11209"
                    fillRule="evenodd"
                    stroke="none"
                    d="M27.317 184.16l-6.949-6.992 6.949-7.002 6.918 7.002-6.918 6.992"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
                <path
                    fill="none"
                    stroke="#d11209"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2.995"
                    d="M162.23 236.57H4.48v-232h157.75v232z"
                    transform="matrix(1 0 0 1.003 0 -.367)"
                ></path>
            </g>
        </svg>
    );
}

export default BackRed;
